
/* ---------------------------------------------------------------------- */
/* Scroll to Top Control
 /* ---------------------------------------------------------------------- */

var scrolltotop = {
    setting: {startline:100, scrollto: 0, scrollduration:500, fadeduration:[500, 100]},
    controlHTML: '', //HTML for control, which is auto wrapped in DIV w/ ID="topcontrol"
    controlattrs: {offsetx:5, offsety:5}, //offset of control relative to right/ bottom of window corner
    anchorkeyword: '#top', //Enter href value of HTML anchors on the page that should also act as "Scroll Up" links

    state: {isvisible:false, shouldvisible:false},

    scrollup:function(){
        if(!this.cssfixedsupport) //if control is positioned using JavaScript
            this.$control.css({opacity:0}); //hide control immediately after clicking it
        var dest=isNaN(this.setting.scrollto)? this.setting.scrollto : parseInt(this.setting.scrollto);
        if(typeof dest=="string" && jQuery('#'+dest).length==1){ //check element set by string exists
            dest=jQuery('#'+dest).offset().top;
        }else{
            dest=0;
        }
        this.$body.animate({scrollTop: dest}, this.setting.scrollduration);
    },

    keepfixed:function(){
        var $window=jQuery(window);
        var controlx=$window.scrollLeft() + $window.width() - this.$control.width() - this.controlattrs.offsetx;
        var controly=$window.scrollTop() + $window.height() - this.$control.height() - this.controlattrs.offsety;
        this.$control.css({left:controlx+'px', top:controly+'px'});
    },

    togglecontrol:function(){
        var scrolltop=jQuery(window).scrollTop();
        if(!this.cssfixedsupport)
            this.keepfixed();
        this.state.shouldvisible=(scrolltop>=this.setting.startline)? true : false;
        if(this.state.shouldvisible && !this.state.isvisible){
            this.$control.stop().animate({opacity:1}, this.setting.fadeduration[0]);
            this.state.isvisible=true;
        }
        else if(this.state.shouldvisible==false && this.state.isvisible){
            this.$control.stop().animate({opacity:0}, this.setting.fadeduration[1]);
            this.state.isvisible=false;
        }
    },

    init:function(){
        jQuery(document).ready(function($){
            var mainobj=scrolltotop;
            var iebrws=document.all;
            mainobj.cssfixedsupport=!iebrws || iebrws && document.compatMode=="CSS1Compat" && window.XMLHttpRequest //not IE or IE7+ browsers in standards mode
            mainobj.$body=(window.opera)? (document.compatMode=="CSS1Compat"? $('html') : $('body')) : $('html,body')
            mainobj.$control=$('<div id="topcontrol">'+mainobj.controlHTML+'</div>')
                .css({position:mainobj.cssfixedsupport? 'fixed' : 'absolute', bottom:mainobj.controlattrs.offsety, right:mainobj.controlattrs.offsetx, opacity:0, cursor:'pointer'})
                .attr({title:'Scroll Back to Top'})
                .click(function(){mainobj.scrollup(); return false})
                .appendTo('body');
            if(document.all && !window.XMLHttpRequest && mainobj.$control.text()!='') //loose check for IE6 and below, plus whether control contains any text
                mainobj.$control.css({width:mainobj.$control.width()}); //IE6- seems to require an explicit width on a DIV containing text
            mainobj.togglecontrol();
            $('a[href="' + mainobj.anchorkeyword +'"]').click(function(){
                mainobj.scrollup();
                return false;
            });
            $(window).bind('scroll resize', function(e){
                mainobj.togglecontrol();
            })
        })
    }
}

scrolltotop.init();

// jQuery Initialization
jQuery(document).ready(function($){

    function isMobile(){
        return navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) != null;
    }

    /* ---------------------------------------------------------------------- */
    /* Homepage Slider
     /* ---------------------------------------------------------------------- */

    if($().nivoSlider){
        var slider = $("#slider");
        if(slider.length > 0){
            slider.nivoSlider({
                effect: isMobile()? 'fade' : 'sliceUpDown', /* possible effects: 'random', 'fade', 'fold', 'sliceDown', 'sliceDownRight', 'sliceDownLeft', 'sliceUpRight', 'sliceUpLeft', 'sliceUpDown','sliceUpDownLeft','boxRandom','boxRain','boxRainReverse' */
                animSpeed: isMobile()? 500 : 1000,
                pauseTime: 7000,
                captionOpacity: 1,
                afterLoad: function(){
                    if(Modernizr.touch && $().swipe) {

                        function doSliderSwipe(e, dir ) {
                            if(dir.toLowerCase() === 'left' ){
                                $('.nivo-nextNav').trigger('click');
                            }
                            if(dir.toLowerCase() === 'right' ){
                                $('.nivo-prevNav').trigger('click');
                            }
                        }

                        slider.swipe({
                            click       : function(e, target){
                                $(target).trigger('click');
                            },
                            swipeLeft       : doSliderSwipe,
                            swipeRight      : doSliderSwipe,
                            allowPageScroll : 'auto'
                        });

                    }
                }
            });
        }
    }




    /* ---------------------------------------------------------------------- */
    /* Lightbox
     /* ---------------------------------------------------------------------- */

    function swipePrettyPhoto(e, dir){
        var picHolder = $(e.currentTarget).closest('.pp_pic_holder');
        if(dir.toLowerCase() === 'left' ){
            picHolder.find('.pp_arrow_next').trigger('click');
        }
        if(dir.toLowerCase() === 'right' ){
            picHolder.find('.pp_arrow_previous').trigger('click');
        }
    }

    function initPrettyPhoto(){
        $("a[rel^='prettyPhoto']").prettyPhoto({
            social_tools: false,
            deeplinking: false,
            allow_resize: true,
            overlay_gallery: isMobile()? false:true,
            modal: true
        });
    }
    function closePrettyPhoto(target){
        if($().prettyPhoto){
            var ppPicHolder = $('.pp_pic_holder');
            if(ppPicHolder.length > 0 && ppPicHolder.is(':visible')){
                if(ppPicHolder.find(target).length == 0){
                    $.prettyPhoto.close();
                }
            }
        }
    }

    if($().prettyPhoto){
        initPrettyPhoto();
    }

    /* ---------------------------------------------------------------------- */
    /* Portfolio Hover Effect
     /* ---------------------------------------------------------------------- */

    function overlay(){
        $(".entry-thumb a, a.lightbox").hover(function(){
            $(this).find('.overlay').stop().animate({opacity: 0.5}, 300);}, function(){
            $(this).find('.overlay').stop().animate({opacity: 0}, 300);
        });
    }

    overlay();

    /* ---------------------------------------------------------------------- */
    /* Carousel
     /* ---------------------------------------------------------------------- */

    if($().carouFredSel){
        var testimonialList = $("#testimonials");
        if(testimonialList.length > 0){
            testimonialList.carouFredSel({
                direction: 'up',
                width: '100%',
                height: 'variable',
                align: 'left',
                items: {
                    visible: 1
                },
                scroll: {
                    items: 1,
                    duration: 1000,
                    pauseOnHover: true,
                    event: 'click',
                    queue: false
                },
                auto: {
                    play: true
                },
                prev: '#testimonial-prev',
                next: '#testimonial-next'

            });
        }
    }

    if($().carouFredSel){
        var testimonialList = $("#testimonials2");
        if(testimonialList.length > 0){
            testimonialList.carouFredSel({
                direction: 'up',
                width: '100%',
                height: 'variable',
                align: 'left',
                items: {
                    visible: 1
                },
                scroll: {
                    items: 1,
                    duration: 1000,
                    pauseOnHover: true,
                    event: 'click',
                    queue: false
                },
                auto: {
                    play: true
                },
                prev: '#testimonial2-prev',
                next: '#testimonial2-next'

            });
        }
    }


    /* ---------------------------------------------------------------------- */
    /* Form Validation
     /* ---------------------------------------------------------------------- */

    if($().validate){
        $("#contact-form").validate({
            rules: {
                name: "required",
                email: "required email",
                message: "required"
            },
            messages: {
                name: "Пожалуйста, введите Ваше имя",
                email: {
                    required: "Это поле необходимо заполнить",
                    email: "Пожалуйста, введите корретный email"
                },
                message: "Пожалуйста, введите текст"

            }
        });
    }

    /*   if($("#contact-form #submit")){
           $("#contact-form #submit").bind("click", function(evt){

               if($("#contact-form").valid()){

   //                $("#submit").attr('disabled', 'disabled');
                   *//*jQuery.ajax({
                    type:"POST",
                    url:"contact.php",
                    data:getFormData(),
                    statusCode: {
                        200: function() {
                            $(".notification-box-success").css('display', '');
                            $("#submit").removeAttr('disabled', '');
                            resetFormData();
                        },
                        500: function() {
                            $(".notification-box-error").css('display', '');
                            $("#submit").removeAttr('disabled');
                        }
                    }
                });*//*
            }

            function getFormData(){
                var data = 'a='+evt.timeStamp;
                if($("#contact-form #name")){
                    data += '&name='+$("#contact-form #name").attr('value');
                }
                if($("#contact-form #email")){
                    data += '&email='+$("#contact-form #email").attr('value');
                }
                if($("#contact-form #website")){
                    data += '&website='+$("#contact-form #website").attr('value');
                }
                if($("#contact-form #subject")){
                    data += '&subject='+$("#contact-form #subject").attr('value');
                }
                if($("#contact-form #message")){
                    data += '&message='+$("#contact-form #message").val();
                }
                return data;
            }

            function resetFormData(){
                var data = 'a='+evt.timeStamp;
                if($("#contact-form #name")){
                    $("#contact-form #name").attr('value', '');
                }
                if($("#contact-form #email")){
                    $("#contact-form #email").attr('value', '');
                }
                if($("#contact-form #website")){
                    $("#contact-form #website").attr('value', '');
                }
                if($("#contact-form #subject")){
                    $("#contact-form #subject").attr('value', '');
                }
                if($("#contact-form #message")){
                    $("#contact-form #message").val('');
                }
                return data;
            }

            return false;
        });
    }
*/
    /* ---------------------------------------------------------------------- */



    /* ---------------------------------------------------------------------- */
    /* Sticky Footer
     /* ---------------------------------------------------------------------- */

    // Set minimum height so that the footer will stay at the bottom of the window even if there isn't enough content
    function setMinHeight() {
        var content = $('#content');
        var body = $('body');
        var container = $('#container');
        content.css('min-height',
            $(window).outerHeight(true)
            - ( body.outerHeight(true) - body.height() )
            - ( container.outerHeight(true) - container.height() )
            - $('#header').outerHeight(true)
            - ( content.outerHeight(true) - content.height() )
            - $('#footer').outerHeight(true)
        );
    }

    // Init
    setMinHeight();

    // Window resize
    $(window).on('resize', function() {

        var timer = window.setTimeout( function() {
            window.clearTimeout( timer );
            setMinHeight();
        }, 30 );

    });

    /* ---------------------------------------------------------------------- */
    /* Mobile Touch Handler
     /* ---------------------------------------------------------------------- */

    if(Modernizr.touch) {
        $(document).on('touchstart', function (e) {
            var target = e.target;
            closeMainMenu(e, target);
            closePrettyPhoto(target);
            closePolyglotLanguageSwitcher(target);
        });
    }else{
        $(document).click(function (e) {
            closePrettyPhoto(e.target);
        });
    }

    /* ---------------------------------------------------------------------- */
    /* Style Switcher
     /* ---------------------------------------------------------------------- */

    if(Modernizr.touch){
        var projectMedia = $('#project-media');
        var projectMediaPrev = projectMedia.find('a.prev');
        var projectMediaNext = projectMedia.find('a.next');

        projectMedia.css('marginBottom', '65px');

        projectMediaPrev.css('marginTop', '0');
        projectMediaPrev.css('bottom', '-45px');
        projectMediaPrev.css('top', 'auto');
        projectMediaPrev.css('backgroundPosition', 'left -31px');
        projectMediaPrev.css('right', '35px');
        projectMediaPrev.css('left', 'auto');

        projectMediaNext.css('marginTop', '0');
        projectMediaNext.css('bottom', '-45px');
        projectMediaNext.css('top', 'auto');
        projectMediaNext.css('backgroundPosition', 'right -31px');
        projectMediaNext.css('right', '0');
    }


});